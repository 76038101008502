import nl from '../locales/nl.json'
import de from '../locales/de.json'
import en from '../locales/en.json'
import store from '@/store/index'

/**
 * swT service
 * @param key The key that is to be translated, determined in the component
 * @param storeLocale The user preference language coming from the store, determined at main.ts
 * @param n Used in pluralization/abbreviation, determined in the component
 *
 * Common use case:
 *
 *  swT('saveProducts')
 *
 * For pluralization 4 keys are possible:
 * saveProducts: Save? // mandatory as it is fallback
 * saveProducts0: There is nothing to save.
 * saveProducts1: Save 1 product?
 * saveProductsS: Save n products? // notice the capital S at the end of the key name
 *
 * Pluralization example:
 *
 *   swT('saveProducts', productsToBeSaved.length)
 *
 * No support for negative values for n, will go to fallback value
 *
 * For abbreviations, use the key with _abr suffix
 * If the abbreviation is not available, it will attempt to show the unabbreviated version and if that one is missing, it will show the key itself
 */

function swT(key: string, n?: number | string): string {
  let locale = en
  const storeLocale = store.state.locale
  if (storeLocale.startsWith('nl')) locale = nl
  if (storeLocale.startsWith('de')) locale = de

  if (n === undefined) return locale[key] || key

  switch (n) {
    case 0:
      return locale[`${key}0`] || key
    case 1:
      return locale[`${key}1`] || key
    case 'abr':
      return locale[`${key}_abr`] || locale[key] || key
    default:
      if (typeof n != 'string' && n > 1) {
        return locale[`${key}S`].replace('{n}', n) || key
      }
  }
}

export { swT }
