<template>
  <v-dialog v-hotkey="keymap" :value="value" max-width="500">
    <v-card>
      <v-card-title class="headline info white--text">
        {{ swT('xls-import') }}
      </v-card-title>
      <v-card-text>
        <v-card class="ma-2" color="green lighten-3">
          <v-card-text>
            {{ swT('xls_import_only_new') }}
          </v-card-text>
          <v-card-actions>
            <v-btn data-test="onyNewButton" large @click.native="$emit('dialogServiceEvent', 'new_only')">
              {{ swT('new_only') }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card class="ma-2" color="warning lighten-3">
          <v-card-text>
            {{ swT('xls_import_only_existing') }}
          </v-card-text>
          <v-card-actions>
            <v-btn data-test="onyExistingButton" large @click.native="$emit('dialogServiceEvent', 'update_only')">
              {{ swT('update_only') }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card class="ma-2" color="error lighten-3">
          <v-card-text>
            {{ swT('xls_import_all') }}
          </v-card-text>
          <v-card-actions>
            <v-btn data-test="allButton" large @click.native="$emit('dialogServiceEvent', 'all')">
              {{ swT('all') }}
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn data-test="cancelButton" color="info" large text @click.native="cancel">
            {{ swT('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { swT } from '@/functions/i18n'
export default {
  props: ['value'],
  data() {
    return {
      swT,
    }
  },
  computed: {
    keymap() {
      return {
        esc: this.cancel,
      }
    },
  },
  methods: {
    cancel() {
      this.$emit('dialogServiceEvent')
    },
  },
}
</script>
