import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import nl from 'vuetify/lib/locale/nl'
import de from 'vuetify/lib/locale/de'
import en from 'vuetify/lib/locale/en'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { nl, de, en },
    current: 'nl',
  },
})
