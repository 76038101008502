import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{directives:[{name:"hotkey",rawName:"v-hotkey",value:(_vm.keymap),expression:"keymap"}],attrs:{"value":_vm.value,"max-width":"500"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline warning white--text"},[_vm._v(" "+_vm._s(_vm.swT('alert'))+" ")]),_c(VCardText,{staticClass:"my-4"},[(_vm.multiLineMessage)?_c('pre',[_vm._v(_vm._s(_vm.message))]):_c('p',[_vm._v(_vm._s(_vm.message))])]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"data-test":"yesButton","color":"green","large":"","text":""},nativeOn:{"click":function($event){return _vm.$emit('dialogServiceEvent', 'yes')}}},[_vm._v(" "+_vm._s(_vm.swT(_vm.yesKey))+" ")]),(_vm.showNoKey)?_c(VBtn,{attrs:{"data-test":"noButton","color":"red","large":"","text":""},nativeOn:{"click":function($event){return _vm.$emit('dialogServiceEvent', 'no')}}},[_vm._v(" "+_vm._s(_vm.swT(_vm.noKey))+" ")]):_vm._e(),_c(VBtn,{attrs:{"data-test":"cancelButton","color":"warning","large":"","text":""},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.swT(_vm.cancelKey))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }