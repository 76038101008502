<template>
  <v-dialog v-hotkey="keymap" :value="value" max-width="500">
    <v-card>
      <v-card-title class="headline warning white--text">
        {{ swT('alert') }}
      </v-card-title>
      <v-card-text class="my-4">
        <p>{{ message }}</p>
      </v-card-text>
      <v-card-actions>
        <v-checkbox v-if="showRemember" v-model="remember" data-test="rememberCheckBox" large text :label="swT('yes_no_remember')"></v-checkbox>
        <v-spacer></v-spacer>
        <v-btn data-test="yesButton" color="green" large text @click.native="$emit('dialogServiceEvent', { answer: true, remember })">
          {{ swT(yesKey) }}
        </v-btn>
        <v-btn data-test="noButton" color="red" large text @click.native="no">
          {{ swT(noKey) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { swT } from '@/functions/i18n'

export default {
  props: { value: Boolean, message: String, showRemember: { type: Boolean, default: false }, yesKey: { type: String, default: 'yes' }, noKey: { type: String, default: 'no' } },
  data() {
    return {
      swT,
      remember: false,
      // todo
    }
  },
  computed: {
    keymap() {
      return {
        esc: this.no,
      }
    },
  },
  methods: {
    no() {
      this.$emit('dialogServiceEvent', { answer: false, remember: this.remember })
    },
  },
}
</script>
