/**
 *
 * The startupDB client takes care of synchronizing data between the server and the browser.
 *
 * It defines a set of CRUD operations to manipulate the data.
 *
 * @param {object} options - Options.
 *  options.url = url to startupDB resource
 *
 * READ (cRud):
 * By creating an instance of the client, the latest checkpoint for the given collection is retrieved from the server and
 * kept in memory by the client.
 *
 * Example:
 * const skuAPI = await startupDB({ url: "localhost:3000/api/skus" })
 *
 * CREATE (Crud):
 * The create method takes an array of objects and sends them to the startupDB server.
 * If the server can create the objects succesfully, it responds with an the opLog that needs to be processed by the
 * client in order to refresh it's data
 *
 * Example:
 * await skuAPI.create(skus)
 *
 * UPDATE (crUd):
 * The update method takes an array of objects and sends them to the startupDB server.
 * If the server can process the updates succesfully, it responds with an the opLog that needs to be processed by the
 * client in order to refresh it's data
 *
 * Example:
 * await skuAPI.update(skus)
 *
 * DELETE (cruD):
 * The delete method takes an array of objects and sends them to the startupDB server.
 * If the server can delete the objects succesfully, it responds with an the opLog that needs to be processed by the
 * client in order to refresh it's data
 *
 * Example:
 * await skuAPI.delete(skus)
 *
 */
import * as jsonPatch from 'fast-json-patch'
import tools from '../functions/tools'
import webServices from '../functions/webServicesFacade'
import { AuditHeadersI, ClientOptionsI, StartupDBApiI, CheckPointI } from '../types/startupDBClient'
import { deepCopy } from '@softwear/latestcollectioncore'
import consts from '../store/consts'
import { get, set, keys, delMany } from 'idb-keyval'

const applyCRUDoperation = function(operation, context) {
  const data = context.checkPoint.data
  const crudOperation = operation.operation
  if (crudOperation == 'delete') {
    for (const item of operation.oldData) {
      delete data[item.id]
    }
  }
  if (crudOperation == 'create') {
    for (const item of operation.data) {
      if (item.id) data[item.id] = item
      else data.push(item)
    }
  }
  if (crudOperation == 'update') {
    for (const item of operation.data) {
      data[item.id] = item
    }
  }
  if (crudOperation == 'patch') {
    for (const item of operation.data) {
      const document = deepCopy(data[item.id] || {})
      if (item.patch) data[item.id] = jsonPatch.applyPatch(document, item.patch).newDocument
      else data[item.id] = Object.assign(document, item)
    }
  }
}

const processOpLog = function(opLog, context) {
  const checkPoint = context.checkPoint
  if (opLog.status == 200) {
    opLog.data.forEach(async function(operation) {
      checkPoint.nextOpLogId = operation.opLogId + 1
      applyCRUDoperation(operation, context)
    })
    return opLog.data.length
  }
  return 0
}

const pullOpLogRaw = async function(context: any) {
  if (context.checkPoint.nextOpLogId > 1 && context.lastOplogId < context.checkPoint.nextOpLogId) return 0
  const opLog = await webServices.startupDBApi.get(`${context.options.url}?fromOpLogId=${context.checkPoint.nextOpLogId - 1}`, tools.apiHeaders())
  return processOpLog(opLog, context)
}

const pullOpLog = async function(this: any) {
  //   await webServices.startupDBApi.get(`${this.options.url}?returnType=tally`, tools.apiHeaders()) // This triggers possibe DB hook on the server
  const checkPointHeader = await webServices.startupDBApi.head(this.options.url, tools.apiHeaders())
  let lastCheckPointTime = checkPointHeader.headers?.['x-last-checkpoint-time'] || '0'
  if (lastCheckPointTime == '0') lastCheckPointTime = '' + new Date().getTime()
  const lastOplogId = parseInt(checkPointHeader.headers?.['x-last-oplog-id'] || '-1')
  return await pullOpLogRaw({ options: this.options, checkPoint: this.checkPoint, lastCheckPointTime: lastCheckPointTime, lastOplogId: lastOplogId })
}
const getObjects = function(this: any, id) {
  if (id) return this.checkPoint.data[id]
  return this.checkPoint.data
}

const patchObjects = async function(this: any, payload, auditHeaders: AuditHeadersI) {
  const NEVER_PATCH = ['/__created', '/__modified']
  // If we're not dealing with an array, create a one object array
  if (!Array.isArray(payload)) payload = [payload]
  const patches = []
  payload.forEach((doc) => {
    if (!doc.id || !this.checkPoint.data[doc.id]) throw 'Will not patch nonexisting documents, use createObjects instead'
    const patch = jsonPatch.compare(this.checkPoint.data[doc.id], doc).filter((op) => !NEVER_PATCH.includes(op.path))
    if (patch.length > 0) patches.push({ id: doc.id, patch: patch })
  })
  if (patches.length == 0) return 0
  const opLog = await webServices.startupDBApi.patch(`${this.options.url}?fromOpLogId=${this.checkPoint.nextOpLogId - 1}`, patches, tools.apiHeaders(auditHeaders))
  return await processOpLog(opLog, this)
}

const updateObjects = async function(this: any, payload, auditHeaders: AuditHeadersI) {
  const opLog = await webServices.startupDBApi.put(`${this.options.url}?fromOpLogId=${this.checkPoint.nextOpLogId - 1}`, payload, tools.apiHeaders(auditHeaders))
  return await processOpLog(opLog, this)
}

const deleteObjects = async function(this: any, payload, auditHeaders: AuditHeadersI) {
  const ids = payload.map((i) => `"${i.id || i.barcode}"`).join(',')
  const opLog = await webServices.startupDBApi.delete(`${this.options.url}?fromOpLogId=${this.checkPoint.nextOpLogId - 1}&filter=id in (${ids})`, tools.apiHeaders(auditHeaders))
  return await processOpLog(opLog, this)
}

const createObjects = async function(this: any, payload, auditHeaders: AuditHeadersI) {
  const opLog = await webServices.startupDBApi.post(`${this.options.url}?fromOpLogId=${this.checkPoint.nextOpLogId - 1}`, payload, tools.apiHeaders(auditHeaders))
  return await processOpLog(opLog, this)
}
async function getCachedChunk(url: string, chunkNr: number): Promise<string | undefined> {
  const cache = await get(`${url}_${chunkNr}_`)
  if (cache) return await cache.text()
  return undefined
}
async function saveChunkToCache(prefix: string, url: string, chunkNr: number, value): Promise<string | undefined> {
  await set(`${prefix}_${url}_${chunkNr++}_`, value ? new Blob([value]) : undefined)
  return undefined
}
function getStringUntilChar(str: string, chr: string): string {
  const index = str.indexOf(chr)
  if (index !== -1) return str.substring(0, index)
  return str
}
async function getMaxChunkNr(url: string): Promise<number> {
  const prefix = getStringUntilChar(`${url}`, '?')
  const nrExistingChunks = (await keys()).filter((k) => (k as string).includes(prefix) && !(k as string).includes('_content_length_')).length
  return nrExistingChunks > 0 ? nrExistingChunks - 1 : 0
}
async function getCachedOplogSize(url: string): Promise<number> {
  const prefix = getStringUntilChar(url, '?')
  const cache = await get(`${prefix}_content_length_`)
  if (cache) return parseInt(cache) || 0
  return 0
}
async function setCachedOplogSize(url: string, size: number): Promise<void> {
  const prefix = getStringUntilChar(`oplog_${url}`, '?')
  await set(`${prefix}_content_length_`, size)
  return
}
async function clearCacheForUrl(url: string): Promise<void> {
  const prefix = getStringUntilChar(url, '?')
  await delMany((await keys()).filter((k) => (k as string).includes(prefix)))
}
const processOpLog25 = async function(oplog: Response | undefined, context: any): Promise<number> {
  const url = context.options.url
  let reader
  if (oplog) {
    reader = oplog.body.pipeThrough(new TextDecoderStream()).getReader()
    const contentLength = parseInt(oplog.headers.get('Content-Length')) || 0
    context.lastOplogSize += contentLength
    await setCachedOplogSize(url, context.lastOplogSize)
  } else {
    context.lastOplogSize = await getCachedOplogSize(`oplog_${url}`)
    if (!(await getCachedChunk(`oplog_${url}`, 0))) return 0
  }
  let chunkNr = oplog ? await getMaxChunkNr(`oplog_${url}`) : 0
  let buf = ''
  let nrOperations = 0
  while (true) {
    let chunk
    if (oplog) {
      const block = await reader.read()
      chunk = block.value
      await saveChunkToCache('oplog', url, chunkNr++, chunk)
      if (block.done) return nrOperations
    } else {
      chunk = await getCachedChunk(`oplog_${url}`, chunkNr++)
      if (!chunk) return nrOperations
    }
    buf += chunk
    do {
      const newLinePos = buf.indexOf('\n')
      if (newLinePos == -1) break
      const line = buf.substring(0, newLinePos)
      buf = buf.substring(newLinePos + 1)
      if (!line) continue
      const operation = JSON.parse(line)
      applyCRUDoperation(operation, context)
      nrOperations++
    } while (true)
  }
}

const fetchOptions = function(method, payload?) {
  const options = tools.apiHeaders() as any
  options.responseType = 'stream'
  options.headers['Accept'] = 'application/x-ndjson'
  if (payload) options.body = JSON.stringify(payload)
  options.method = method
  return options
}

const pullOplogRaw25 = async function(context: any) {
  if (context.opLogSize <= context.lastOplogSize) return 0
  const opLog = await fetch(`${consts.webServicesPath}/data${context.options.url}?fromOpLogId=${context.lastOplogSize}`, fetchOptions('GET'))
  // context.lastOplogSize = context.opLogSize
  console.log('opLogSize vs expected', context.lastOplogSize, context.opLogSize, context.options.url)
  return await processOpLog25(opLog, context)
}
const pullOplogRawFromCache = async function(context: any) {
  return await processOpLog25(undefined, context)
}

const pullOplog25 = async function(this: any) {
  //   await webServices.startupDBApi.get(`${this.options.url}?returnType=tally`, tools.apiHeaders()) // This triggers possibe DB hook on the server
  const checkPointHeader = await webServices.startupDBApi.head(this.options.url, tools.apiHeaders())
  let lastCheckPointTime = checkPointHeader.headers?.['x-last-checkpoint-time'] || '0'
  if (lastCheckPointTime == '0') lastCheckPointTime = '' + new Date().getTime()
  const oplogSize = parseInt(checkPointHeader.headers?.['x-last-oplog-id'])
  return await pullOplogRaw25({
    options: this.options,
    checkPoint: this.checkPoint,
    lastCheckPointTime: lastCheckPointTime,
    lastOplogSize: this.lastOplogSize,
    opLogSize: oplogSize,
  })
}

const patchObjects25 = async function(this: any, payload) {
  const NEVER_PATCH = ['/__created', '/__modified']
  // If we're not dealing with an array, create a one object array
  if (!Array.isArray(payload)) payload = [payload]
  const patches = []
  payload.forEach((doc) => {
    if (!doc.id || !this.checkPoint.data[doc.id]) throw 'Will not patch nonexisting documents, use createObjects instead'
    const patch = jsonPatch.compare(this.checkPoint.data[doc.id], doc).filter((op) => !NEVER_PATCH.includes(op.path))
    if (patch.length > 0) patches.push({ id: doc.id, patch: patch })
  })
  if (patches.length == 0) return 0
  const opLog = await fetch(`${consts.webServicesPath}/data${this.options.url}?fromOpLogId=${this.lastOplogSize}`, fetchOptions('PATCH', patches))
  return await processOpLog25(opLog, this)
}

const updateObjects25 = async function(this: any, payload) {
  const opLog = await fetch(`${consts.webServicesPath}/data${this.options.url}?fromOpLogId=${this.lastOplogSize}`, fetchOptions('PUT', payload))
  return await processOpLog25(opLog, this)
}

const deleteObjects25 = async function(this: any, payload) {
  const ids = payload.map((i) => `"${i.id || i.barcode}"`).join(',')
  const opLog = await fetch(`${consts.webServicesPath}/data${this.options.url}?fromOpLogId=${this.lastOplogSize}&filter=id in (${ids})`, fetchOptions('DELETE', payload))
  return await processOpLog25(opLog, this)
}

const createObjects25 = async function(this: any, payload) {
  const opLog = await fetch(`${consts.webServicesPath}/data${this.options.url}?fromOpLogId=${this.lastOplogSize}`, fetchOptions('POST', payload))
  return await processOpLog25(opLog, this)
}
const readCheckpointFromStream = async function(url: string): Promise<{ data: CheckPointI }> {
  let chunkNr = 0
  let reader = null
  let checkPoint = null
  let buf = ''
  if (!(await getCachedChunk(`checkPoint_${url}`, 0))) {
    const response = await fetch(consts.webServicesPath + '/data/' + url, fetchOptions('GET'))
    reader = response.body.pipeThrough(new TextDecoderStream()).getReader()
    await clearCacheForUrl(url)
  }
  while (true) {
    let chunk
    if (!reader) {
      chunk = await getCachedChunk(`checkPoint_${url}`, chunkNr++)
      if (!chunk) return { data: checkPoint || { data: {}, nextOplogId: 0 } }
    } else {
      const block = await reader.read()
      chunk = block.value
      await saveChunkToCache('checkPoint', url, chunkNr++, chunk)
      if (block.done) return { data: checkPoint || { data: {}, nextOplogId: 0 } }
    }
    buf += chunk
    do {
      const newLinePos = buf.indexOf('\n')
      if (newLinePos == -1) break
      const line = buf.substring(0, newLinePos)
      buf = buf.substring(newLinePos + 1)
      if (!line) continue
      const obj = JSON.parse(line)
      if (checkPoint == null) {
        checkPoint = obj as CheckPointI
      } else {
        if (Array.isArray(checkPoint.data)) checkPoint.data.push(obj)
        else checkPoint.data[obj.id] = obj
      }
    } while (true)
  }
}

export default async function(options: ClientOptionsI): Promise<StartupDBApiI> {
  if (['edge.latestcollection.fashion'].includes(window.location.hostname)) {
    //   if (['127.0.0.1', '192.168.2.7', 'localhost'].includes(window.location.hostname) || ['edge.latestcollection.fashion'].includes(window.location.hostname)) {
    let lastCheckPointTime: string
    let lastOplogSize: number
    if (!options.immutable) {
      const checkPointHeader = await webServices.startupDBApi.head(options.url, tools.apiHeaders())
      lastCheckPointTime = checkPointHeader.headers?.['x-last-checkpoint-time']
      lastOplogSize = parseInt(checkPointHeader.headers?.['x-last-oplog-id']) || 0
    } else {
      lastCheckPointTime = 'immutable'
      lastOplogSize = 0
    }
    if (lastCheckPointTime == '0') lastCheckPointTime = '' + new Date().getTime()

    // The checkpoint we just received might have been cached locally and not reflect the most recent operations
    const checkPoint = (await readCheckpointFromStream(`${options.url}?returnType=checkPoint&cacheBuster=${lastCheckPointTime}`)) as { data: CheckPointI }
    const context = { options: options, checkPoint: checkPoint.data, lastCheckPointTime: lastCheckPointTime, lastOplogSize: 0, opLogSize: lastOplogSize }
    await pullOplogRawFromCache(context)
    await pullOplogRaw25(context)

    return {
      options: options,
      checkPoint: checkPoint.data,
      lastCheckPointTime: lastCheckPointTime,
      lastOplogId: 0,
      lastOplogSize: context.lastOplogSize,
      pull: pullOplog25,
      read: getObjects,
      update: updateObjects25,
      delete: deleteObjects25,
      create: createObjects25,
      patch: patchObjects25,
    }
  } else {
    let lastCheckPointTime: string
    let lastOplogId: number
    if (!options.immutable) {
      const checkPointHeader = await webServices.startupDBApi.head(options.url, tools.apiHeaders())
      lastCheckPointTime = checkPointHeader.headers?.['x-last-checkpoint-time'] || '0'
      lastOplogId = parseInt(checkPointHeader.headers?.['x-last-oplog-id'] || '-1')
    } else {
      lastCheckPointTime = 'immutable'
      lastOplogId = -1
    }
    if (lastCheckPointTime == '0') lastCheckPointTime = '' + new Date().getTime()
    const checkPoint = (await webServices.startupDBApi.get(`${options.url}?returnType=checkPoint&cacheBuster=${lastCheckPointTime}`, tools.apiHeaders())) as { data: CheckPointI }
    // The checkpoint we just received might have been cached locally and not reflect the most recent operations
    await pullOpLogRaw({ options: options, checkPoint: checkPoint.data, lastCheckPointTime: lastCheckPointTime, lastOplogId: lastOplogId })
    return {
      options: options,
      checkPoint: checkPoint.data,
      lastCheckPointTime: lastCheckPointTime,
      lastOplogId: lastOplogId,
      lastOplogSize: 0,
      pull: pullOpLog,
      read: getObjects,
      update: updateObjects,
      delete: deleteObjects,
      create: createObjects,
      patch: patchObjects,
    }
  }
}
