function setLanguage(store: any, languageCode: string): string {
  if (store.state.activeConfig?.UISettings) {
    store.dispatch('updatePreference', { key: 'UISettings.language', value: languageCode })
  } else {
    store.state.locale = languageCode
  }
  return languageCode
}

function checkLanguage(store: any): string {
  let savedLanguage

  if (store.state.activeConfig?.UISettings) {
    if (store.state.beforeAuthLanguage == '') {
      savedLanguage = store.state.activeConfig.UISettings?.language.value
    } else {
      savedLanguage = setLanguage(store, store.state.beforeAuthLanguage)
      store.state.beforeAuthLanguage = ''
    }
  } else {
    savedLanguage = setLanguage(store, store.state.locale)
  }

  if (savedLanguage != undefined) {
    store.state.locale = savedLanguage

    return savedLanguage
  } else {
    return ''
  }
}
export default {
  checkLanguage,
  setLanguage,
}
