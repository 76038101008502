<template>
  <v-dialog v-hotkey="keymap" :value="value" max-width="500">
    <v-card>
      <v-card-title :class="`headline ${type ? type : 'info'} white--text`">
        {{ swT(`${type ? (type === 'warning' ? 'alert' : type) : 'info'}`) }}
      </v-card-title>
      <v-card-text class="my-4">
        <p>{{ message }}</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :color="type ? type : 'info'" large text @click.native="ok">{{ swT('ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { swT } from '@/functions/i18n'
import { router } from '../router'

export default {
  props: ['value', 'type', 'message', 'routeOnOK'],
  data() {
    return {
      swT,
      // todo
    }
  },
  computed: {
    keymap() {
      return {
        enter: this.ok,
        esc: this.ok,
      }
    },
  },
  methods: {
    ok() {
      this.$emit('dialogServiceEvent')
      if (this.routeOnOK) router.push({ path: this.routeOnOK })
    },
  },
}
</script>
