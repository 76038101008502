<template>
  <v-dialog v-model="value" v-hotkey="keymap" persistent max-width="800" width="600px">
    <v-card class="pb-2 white">
      <v-card class="sticky-element px-2 mb-2 mx-0">
        <v-card-text>
          <v-row data-test="brand-title mx-1 px-0">
            <v-col cols="12" sm="3" class="d-flex justify-start">
              <div class="text-h6">
                {{ swT('inventorychange') }}
              </div>
            </v-col>
            <v-col cols="12" sm="9" class="d-flex align-center justify-end">
              <v-card-actions>
                <v-btn class="mx-2" color="success" @click="$emit('dialogServiceEvent', true)">{{ swT('applyChanges') }}</v-btn>
                <v-btn class="mx-1" color="error" @click="close">{{ swT('close') }}</v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card-text>
        <v-text-field v-if="skus.length > 0" v-model="search" append-icon="mdi-magnify" label="Search" dense outlined hide-details class="my-3"></v-text-field>
        <v-data-table v-if="skus.length > 0" :headers="headers" :items="skus" :search="search" :items-per-page="5"></v-data-table>
        <v-alert v-else type="info">{{ swT('no_skus_available') }}</v-alert>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { swT } from '@/functions/i18n'

export default {
  props: ['value', 'skus', 'applyChanges'],
  data() {
    return {
      swT,
      search: '',
      headers: [
        { text: swT('barcode'), value: 'barcode' },
        { text: swT('old_qty'), value: 'oldQty' },
        { text: swT('correction'), value: 'qty' },
        { text: swT('new_qty'), value: 'newQty' },
      ],
    }
  },
  computed: {
    keymap() {
      return {
        esc: this.close,
      }
    },
  },
  methods: {
    close() {
      this.$emit('dialogServiceEvent')
    },
  },
}
</script>
