import Vue from 'vue'
import swPromptDialog from '@/components/swPromptDialog.vue'
import swInfoDialog from '@/components/swInfoDialog.vue'
import swInventoryChangeDialog from '@/components/swInventoryChangeDialog.vue'
import swPrintDialog from '@/components/swPrintDialog.vue'
import swValuePickDialog from '@/components/swValuePickDialog.vue'
import swXLSImportDialog from '@/components/swXLSImportDialog.vue'
import swYesNoDialog from '@/components/swYesNoDialog.vue'
import swYesNoCancelDialog from '@/components/swYesNoCancelDialog.vue'
import vuetify from '@/plugins/vuetify'
import store from '../store/index'

// A map to associate each component name with its component and the events it can emit
const dialogMap = {
  swPromptDialog: { component: swPromptDialog, emitEventValue: 'value' },
  swInfoDialog: { component: swInfoDialog, emitEventValue: null },
  swInventoryChangeDialog: { component: swInventoryChangeDialog, emitEventValue: 'value' },
  swPrintDialog: { component: swPrintDialog, emitEventValue: null },
  swValuePickDialog: { component: swValuePickDialog, emitEventValue: 'value' },
  swXLSImportDialog: { component: swXLSImportDialog, emitEventValue: 'value' },
  swYesNoDialog: { component: swYesNoDialog, emitEventValue: 'value' },
  swYesNoCancelDialog: { component: swYesNoCancelDialog, emitEventValue: 'value' },
}

// Handles the action when a dialog button is pressed
function handleButtonPress(component, resolve, value) {
  resolve(value) // Resolve the promise with the provided value

  // Remove the component from the DOM and destroy the Vue instance
  component.$destroy()
  document.body.removeChild(component.$el)
  store.state.dialogDisplayed = false // Mark that no dialog is currently displayed
}

// Creates and displays the dialog component
function createDialogComponent(dialog, props) {
  // Set the dialogDisplayed state to true to prevent interference with keyboard shortcuts on the page
  store.state.dialogDisplayed = true

  // Create a new Vue instance for the dialog component
  const DialogConstructor = Vue.extend(dialog.component)
  const dialogInstance = new DialogConstructor({
    propsData: props,
    vuetify, // Inject the Vuetify instance here
  })

  // Manually mount the dialog instance to an element
  dialogInstance.$mount(document.createElement('div'))

  // Append the dialog component to the DOM
  document.body.appendChild(dialogInstance.$el)

  // Return a promise to handle events emitted by the dialog component
  return new Promise((resolve) => {
    dialogInstance.$on('dialogServiceEvent', (value) => {
      handleButtonPress(dialogInstance, resolve, dialog.emitEventValue ? value : null)
    })
  })
}

// Public function to show a dialog based on the component name and provided properties
export function showDialog(componentName: string, props: any) {
  if (!componentName) throw new Error(`Component name is not provided.`)

  const dialog = dialogMap[componentName]
  if (!dialog) throw new Error(`Component name "${componentName}" is not defined.`)

  // Set the value property to true to make the dialog visible by default
  if (props.constructor === Object) props['value'] = true
  return createDialogComponent(dialog, props)
}
