import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{directives:[{name:"hotkey",rawName:"v-hotkey",value:(_vm.keymap),expression:"keymap"}],attrs:{"persistent":"","max-width":"800","width":"600px"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c(VCard,{staticClass:"pb-2 white"},[_c(VCard,{staticClass:"sticky-element px-2 mb-2 mx-0"},[_c(VCardText,[_c(VRow,{attrs:{"data-test":"brand-title mx-1 px-0"}},[_c(VCol,{staticClass:"d-flex justify-start",attrs:{"cols":"12","sm":"3"}},[_c('div',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.swT('inventorychange'))+" ")])]),_c(VCol,{staticClass:"d-flex align-center justify-end",attrs:{"cols":"12","sm":"9"}},[_c(VCardActions,[_c(VBtn,{staticClass:"mx-2",attrs:{"color":"success"},on:{"click":function($event){return _vm.$emit('dialogServiceEvent', true)}}},[_vm._v(_vm._s(_vm.swT('applyChanges')))]),_c(VBtn,{staticClass:"mx-1",attrs:{"color":"error"},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.swT('close')))])],1)],1)],1)],1)],1),_c(VCardText,[(_vm.skus.length > 0)?_c(VTextField,{staticClass:"my-3",attrs:{"append-icon":"mdi-magnify","label":"Search","dense":"","outlined":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),(_vm.skus.length > 0)?_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.skus,"search":_vm.search,"items-per-page":5}}):_c(VAlert,{attrs:{"type":"info"}},[_vm._v(_vm._s(_vm.swT('no_skus_available')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }