import globalStore from '../store/globalStore'
import { jsPDF } from 'jspdf'
import tools from './tools'
import { format } from 'date-fns'
import { deepCopy } from '@softwear/latestcollectioncore'
import { swT } from '@/functions/i18n'

// We have to declare some functions that will be called by other functions but als have to call those other functions
// We overwrite the function definition as soon as those other functions have been declared
// eslint-disable-next-line
let drawStaticPartOfPage = function(doc, layout, printBuffer, paperSize, store) {
  // to be assigned later
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let addObjectToPDF = function(originX, originY, doc, object, printBuffer, paperSize, layout, store, rootPrintBuffer, containerChain): { x: number; y: number } {
  // to be assigned later
  return { x: 0, y: 0 }
}

const formatDate = function(date) {
  return format(new Date(date), 'MM-dd-yy HH:mm:ss')
}

function truncateTextToFitWidth(doc, text: string, maxWidth: number) {
  if (doc.getTextWidth(text) <= maxWidth) return text
  const ELLIPSIS = '...'

  let truncatedText = ''
  for (let i = 0; i < text.length; i++) {
    const tempText = truncatedText + text[i]
    if (doc.getTextWidth(tempText + ELLIPSIS) > maxWidth) break
    truncatedText = tempText
  }
  return truncatedText + ELLIPSIS
}

// prettier-ignore
function drawSimpleObject(x, y, doc, object, printBuffer, width, height, store) {
    let text = "" // Will hold text to display for either 'text' or 'field'

    // Get text from object for text objects
    if (object.type == "text") {
        text = swT(object.text)
    }
    // Get text from printBuffer for field objects
    if (object.type == "field") {
        if (object.source && printBuffer) {
            text = tools.getProperty(object.source, printBuffer)
        } else {
            text = printBuffer
        }
        if (typeof (text) == 'number') text = "" + text
        if (object.format == "date") text = formatDate(parseInt(text))
        if (text === undefined || typeof (text) != 'string') {
            store.dispatch("raiseAlert", { header: "fieldnotfound", body: object.source, type: "warning", timeout: 10000 })
            text = ""
        }
        if (object.format == "currency") text = String.fromCharCode(128) + " " + parseFloat(text).toFixed(2)
    }
    // Manupilate case as specified in object
    if (object.case == 1) text = text.toUpperCase()
    if (object.case == 2) text = text.toLowerCase()

    // Draw rectangle for rectangle objects
    if (object.type == "rectangle") {
        doc.rect(x, y, width, height)
    }

    // Draw image for image objects
    if (object.type == "image" && object.image) {
        doc.addImage(object.image, x, y, width, height)
    }

    // Draw text for text and field objets
    if ((object.type == "text" || object.type == "field") && text) {
        let textAlign = null
        if (object.textAlign == 1) textAlign = "left"
        if (object.textAlign == 2) {
            textAlign = "center"
            x = x + width / 2
        }
        if (object.textAlign == 3) {
            textAlign = "right"
            x = x + width
        }

        doc.setFont(object.fontFamily,(object.fontStyle>>1)%2?'italic':'',(object.fontStyle>>0)%2?'bold':'')
        doc.setFontSize(object.fontSize / 4)
        doc.text(truncateTextToFitWidth(doc,text, width), x, y, { align: textAlign, baseline: 'hanging' })
    }
}

// prettier-ignore
function addPage(originX,originY,doc, layout, rootPrintBuffer, paperSize, store,containerChain){
    doc.addPage()
    drawStaticPartOfPage(doc, layout, rootPrintBuffer, paperSize, store)
    const absoluteLowerRightHandSide = {x:originX / 10, y:originY / 10}
    // Now draw all the parent containers of the current container
    if (containerChain.length > 0) {
        // Let origin start at (x,y) of the outermost container
        originX = containerChain[0].object.x
        originY = containerChain[0].object.y
        absoluteLowerRightHandSide.x = originX / 10
        absoluteLowerRightHandSide.y = originY / 10
        containerChain.forEach(link => {
            link.object.children.forEach(child => {
                if (!child.snapToBottom && (child.type != 'container' || child.repeatOnOverflow)) {
                    const childLowerRightHandSide = addObjectToPDF(originX, originY, doc, child, link.printBuffer, paperSize, layout, store, rootPrintBuffer, [])
                    absoluteLowerRightHandSide.x = Math.max(absoluteLowerRightHandSide.x, childLowerRightHandSide.x)
                    absoluteLowerRightHandSide.y = Math.max(absoluteLowerRightHandSide.y, childLowerRightHandSide.y)
                }
            })
            if (link.object.repeatContainer == "horizontal") originX = absoluteLowerRightHandSide.x * 10
            if (link.object.repeatContainer == "vertical") originY = absoluteLowerRightHandSide.y * 10
        })
    }
    return {originX:originX, originY: originY, x:absoluteLowerRightHandSide.x, y:absoluteLowerRightHandSide.y}
}

//prettier-ignore
function drawBottomDwellers(object: any, originX: number,originY: number, absoluteLowerRightHandSide: { x: number; y: number }, bottomChildY: number, paperSize: any,  doc: any, layout: any, rootPrintBuffer: any, store: any, newContainerChain: any, container: any) {
    let childRelativeToBottomDrawn = false
    let firstBottomRelativeChildY = Math.min(...(
        object.children.
            filter(child => child.snapToBottom).
            map(child => child.y)))
    object.children.forEach(function(child) {
        if ( child.snapToBottom) { // Objects relative to bottom/right
            if (!childRelativeToBottomDrawn)
                originY = absoluteLowerRightHandSide.y * 10
            childRelativeToBottomDrawn = true
            // child.text = '======'+originX+':'+originY+'======'
            if (object.pageBreak || originY + object.height - bottomChildY  > paperSize.height - paperSize.footerHeight) {
                // We ran out of paper
                const newOrigin = addPage(originX, originY, doc, layout, rootPrintBuffer, paperSize, store, newContainerChain)
                originX = newOrigin.originX
                originY = newOrigin.originY
                absoluteLowerRightHandSide = { x: newOrigin.x, y: newOrigin.y }
                firstBottomRelativeChildY = 0
            }
            // originY = absoluteLowerRightHandSide.y * 10
            const childLowerRightHandSide = addObjectToPDF(originX, originY - firstBottomRelativeChildY, doc, child, container, paperSize, layout, store, rootPrintBuffer, [])
            absoluteLowerRightHandSide.x = Math.max(absoluteLowerRightHandSide.x, childLowerRightHandSide.x)
            absoluteLowerRightHandSide.y = Math.max(absoluteLowerRightHandSide.y, childLowerRightHandSide.y)
            absoluteLowerRightHandSide.y = Math.max(absoluteLowerRightHandSide.y, (originY + object.height - firstBottomRelativeChildY) / 10)
        }
    })
    if (childRelativeToBottomDrawn)
        originY = absoluteLowerRightHandSide.y * 10
    return {originX:originX, originY: originY, x:absoluteLowerRightHandSide.x, y:absoluteLowerRightHandSide.y}
}

/**
 * Add an object to the PDF document.
 *
 * Use originX,originY as origin to displace the object's own x,y coordinates
 */
addObjectToPDF = function(originX, originY, doc, object, printBuffer, paperSize, layout, store, rootPrintBuffer, containerChain): { x: number; y: number } {
  const x = (originX + object.x) / 10
  const y = (originY + object.y) / 10
  const width = object.width / 10
  const height = object.height / 10
  // Keep track of the lower righthandside of all objects in this container
  // We need to return this object so whatever calls addObjectToPDF knows how big our drawing was
  let absoluteLowerRightHandSide = { x: x + width, y: y + height }

  if (!object.active) return absoluteLowerRightHandSide
  if (object.type != 'container') drawSimpleObject(x, y, doc, object, printBuffer, width, height, store)

  // Recursively draw all child objects from a container object
  if (object.type == 'container') {
    let originX = x * 10,
      originY = y * 10

    const source = tools.getProperty(object.source, printBuffer)
    if (source === undefined) {
      store.dispatch('raiseAlert', { header: 'containernotfound', body: object.source, type: 'warning', timeout: 10000 })
      return absoluteLowerRightHandSide
    }
    const nrContainers = source.length
    for (let containerIndex = 0; containerIndex < nrContainers; containerIndex++) {
      const newContainerChain = containerChain.filter(function() {
        return true
      })
      const container = source[containerIndex]
      absoluteLowerRightHandSide = { x: originX / 10, y: originY / 10 }
      if (object.name == 'products' && (object.pageBreak || originY + object.height + 200 > paperSize.height - paperSize.footerHeight)) {
        // We ran out of paper
        originX = x * 10
        originY = y * 10
        const newOrigin = addPage(originX, originY, doc, layout, rootPrintBuffer, paperSize, store, newContainerChain)
        originX = newOrigin.originX
        originY = newOrigin.originY
        absoluteLowerRightHandSide = { x: newOrigin.x, y: newOrigin.y }
      }
      // normal objects first, then containers
      let bottomChildY = 0
      object.children.forEach(function(child) {
        if (child.type != 'container' && !child.snapToBottom) {
          // Objects relative to top/left
          bottomChildY = Math.max(bottomChildY, child.y + child.height)
          const childLowerRightHandSide = addObjectToPDF(originX, originY, doc, child, container, paperSize, layout, store, rootPrintBuffer, [])
          absoluteLowerRightHandSide.x = Math.max(absoluteLowerRightHandSide.x, childLowerRightHandSide.x)
          absoluteLowerRightHandSide.y = Math.max(absoluteLowerRightHandSide.y, childLowerRightHandSide.y)
        }
      })
      newContainerChain.push({ object: object, printBuffer: container })
      object.children.forEach(function(child) {
        if (child.type == 'container' && !child.snapToBottom) {
          bottomChildY = Math.max(bottomChildY, child.y + child.height)
          const childLowerRightHandSide = addObjectToPDF(originX, originY, doc, child, container, paperSize, layout, store, rootPrintBuffer, newContainerChain)
          absoluteLowerRightHandSide.x = Math.max(absoluteLowerRightHandSide.x, childLowerRightHandSide.x)
          absoluteLowerRightHandSide.y = Math.max(absoluteLowerRightHandSide.y, childLowerRightHandSide.y)
        }
      })
      if (containerIndex < nrContainers - 1) {
        if (object.repeatContainer == 'horizontal') originX = absoluteLowerRightHandSide.x * 10
        if (object.repeatContainer == 'vertical') originY = absoluteLowerRightHandSide.y * 10
        if (object.pageBreak || originY + object.height - bottomChildY > paperSize.height - paperSize.footerHeight) {
          // We ran out of paper
          const newOrigin = addPage(originX, originY, doc, layout, rootPrintBuffer, paperSize, store, newContainerChain)
          originX = newOrigin.originX
          originY = newOrigin.originY
          absoluteLowerRightHandSide = { x: newOrigin.x, y: newOrigin.y }
          const newestOrigin = drawBottomDwellers(
            object,
            originX,
            originY,
            absoluteLowerRightHandSide,
            bottomChildY,
            paperSize,
            doc,
            layout,
            rootPrintBuffer,
            store,
            newContainerChain,
            container
          )
          originX = newestOrigin.originX
          originY = newestOrigin.originY
          absoluteLowerRightHandSide = { x: newestOrigin.x, y: newestOrigin.y }
          continue
        }
      }
      const newestOrigin = drawBottomDwellers(
        object,
        originX,
        originY,
        absoluteLowerRightHandSide,
        bottomChildY,
        paperSize,
        doc,
        layout,
        rootPrintBuffer,
        store,
        newContainerChain,
        container
      )
      originX = newestOrigin.originX
      originY = newestOrigin.originY
      absoluteLowerRightHandSide = { x: newestOrigin.x, y: newestOrigin.y }
    }
  }
  return absoluteLowerRightHandSide
}

/**
 * Draw all non-container objects on the page
 */
drawStaticPartOfPage = function(doc, layout, printBuffer, paperSize, store) {
  layout.objects.forEach(function(object) {
    if (object.type != 'container') {
      addObjectToPDF(0, 0, doc, object, printBuffer, paperSize, layout, store, printBuffer, [])
    }
  })
}

function sortVertical(objects) {
  objects.sort((a, b) => a.y - b.y)
  objects.forEach((element) => {
    if (element.children) sortVertical(element.children)
  })
}
// prettier-ignore
function genPDF(layout, printBuffer, store) {
    // Make a deep copy of the layout so we can add temporary properties  
    layout = deepCopy(layout)
    sortVertical(layout.objects)
    // Load images for static images
    layout.objects.forEach(object => {
        if (object.type != "image") return
        const image = new window.Image()
        image.crossOrigin = "anonymous"
        if (object.name == "Logo") {
            if (store.state.user.settings.tenant.settings.logoUrl?.fullsize) {
                image.src = store.state.user.settings.tenant.settings.logoUrl.fullsize
            } else {
                image.src = "https://www.softwear.nl/images/sfeer/s.png"
            }
        } else {
            image.src = object.url
        }
        image.onload = function() {
            this['image'] = image
        }
        object.image = image
    })
    const paperSize = layout.paperSize
    const doc = new jsPDF({
        orientation: paperSize.width > paperSize.height ? "landscape" : "portrait",
        format: [paperSize.width, paperSize.height]
    })
    paperSize.width *= 10
    paperSize.height *= 10
    paperSize.footerHeight *= 10
    drawStaticPartOfPage(doc, layout, printBuffer, paperSize, store)

    // Draw containers
    layout.objects.forEach(function(object) {
        if (object.type == "container") {
            addObjectToPDF(0, 0, doc, object, printBuffer, paperSize, layout, store, printBuffer, [])
        }
    })
    return doc
}

/**
 * Get layout definition
 *
 * If a layout can be found in the tenants layout collection, take it from there,
 * otherwise use the masterlayout.
 */
function getLayout(layoutId: string) {
  const tenantLayout = globalStore.getLatestCollectionArray('layout').find((layout) => layout.name == layoutId)
  if (tenantLayout?.active) return tenantLayout

  const masterLayout = globalStore.getLatestCollectionArray('masterlayout').find((layout) => layout.name == layoutId)
  return masterLayout
}

export default {
  getLayout,
  genPDF,
}
