import consts from '../store/consts'
import tools from './tools'

/**
 * This function composes a configuration object that holds all settings that influence the UI.
 * consts.allConfig lists all settings including default values and the 'level' at which they can be overwritten (tenant/user)
 * Some of them are defined at the tenant level, some at the user level.
 *
 * @private
 * @param {String} locale used to localize date objects
 * @param {Object} userPreference userPreference object taken holding user preferences
 * @param {Object} teantConfig tenantPreference object taken holding tenant preferences
 */
function loadPreferences(locale: string, userPreference: object, tenantPreference: object) {
  const activeConfig = consts.allConfig
  // Init default values
  tools.forEach(activeConfig, function(value, rootProp) {
    tools.forEach(value, function(value, prop) {
      activeConfig[rootProp][prop].value = value.default
      if (value.type == 'date') {
        activeConfig[rootProp][prop].value = new Date()
        activeConfig[rootProp][prop].value = activeConfig[rootProp][prop].value.toLocaleDateString(locale)
        //activeConfig[rootProp][prop].value = activeConfig[rootProp][prop].value.toISOString().substring(0, 10)
      }
    })
  })
  // Apply tenant settings
  tools.forEach(tenantPreference, function(value, rootProp) {
    tools.forEach(value, function(value, prop) {
      if (activeConfig[rootProp]?.[prop]?.level == 'tenant' && 'value' in value) {
        activeConfig[rootProp][prop].value = value.value
      }
    })
  })
  // Apply user settings
  tools.forEach(userPreference, function(value, rootProp) {
    tools.forEach(value, function(value, prop) {
      if (activeConfig[rootProp]?.[prop]?.level == 'user' && 'value' in value) {
        activeConfig[rootProp][prop].value = value.value
      }
    })
  })
  return activeConfig
}
/* Determine if a user has a particular role
 * Params:
 *   roles: An array of roles the user has
 *   role:  Comma separated string of role(s) or role-prefix(es) to verify
 *
 *
 * Returns true if one of the roles in "role" appears in the array "roles"
 * If "role" is empty, no role is needed so access is granted
 * A role can either be a fully qualified string that needs to match exactly with a given role in "roles"
 * or it can be a prefix for one of the role patterns ...-user, ..._user, ...-admin
 */
function hasRole(roles, role) {
  if (!role) return true
  if (!roles) return false
  let hasRole = false
  role.split(',').forEach((oneRole) => {
    if (
      roles.indexOf(oneRole + '-user') >= 0 ||
      roles.indexOf(oneRole + '_user') >= 0 ||
      roles.indexOf(oneRole + '-admin') >= 0 ||
      roles.indexOf(oneRole + '_admin') >= 0 ||
      roles.indexOf(oneRole + '-consultant') >= 0 ||
      roles.indexOf(oneRole) >= 0
    ) {
      hasRole = true
    }
  })
  return hasRole
}

function hasDataProviderSubscription(dataProvider, state) {
  return state.activeConfig.partnerchannels[dataProvider]?.value
}

export default {
  loadPreferences,
  hasRole,
  hasDataProviderSubscription,
}
