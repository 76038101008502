import barcodePDF from '../functions/barcodePDF'
import zplFunctions from '../functions/renderZPL'
import reports from '../functions/reports'
import { SkuI, vatCategoryE } from '@softwear/latestcollectioncore'
import webServices from '../functions/webServicesFacade'

async function genZpl(layout, printBuffer) {
  return zplFunctions.genZPL(layout, { ...printBuffer, skus: printBuffer.skus })
}
const zplPreview = async function(layout, printBuffer) {
  const zpl = await genZpl(layout, printBuffer)
  window.open('http://api.labelary.com/v1/printers/8dpmm/labels/6x4/0/' + zpl, '_blank').focus()
  return true
}

const sendPayloadToPrintQueue = function(queueName, payload) {
  console.log('print to queue', queueName, payload)
  webServices.sendToPrintQueue(queueName, payload)
}

const zplPrintQueue = async function(layout, printBuffer, strategyParameters) {
  const zpl = await genZpl(layout, printBuffer)
  sendPayloadToPrintQueue(strategyParameters.queueName, zpl)
  return true
}
const zplDirect = async function(layout, printBuffer) {
  const zpl = await genZpl(layout, printBuffer)
  console.log('ZPL', zpl)
  try {
    const device = await navigator.usb.requestDevice({
      filters: [{ classCode: 0x07 }],
    })
    await device.open()
    await device.selectConfiguration(1)
    await device.claimInterface(0)
    const endpoint = device.configuration.interfaces[0].alternate.endpoints.find((e) => e.direction == 'out').endpointNumber
    const encoder = new TextEncoder()
    const usbData = encoder.encode(zpl)
    await device.transferOut(endpoint, usbData)
    return true
  } catch (e) {
    console.log('webUsbError', e)
    return false
  }
}
const pdfDownload = async function(layout, printBuffer) {
  const doc = barcodePDF.genPDF(layout, printBuffer.skus)
  doc.save(`${layout.name}.pdf`)
  return true
}
const pdfPreview = async function(layout, printBuffer) {
  const doc = barcodePDF.genPDF(layout, printBuffer.skus)
  doc.output('dataurlnewwindow')
  return true
}
const pdfPrintQueue = async function(layout, printBuffer, strategyParameters) {
  const doc = barcodePDF.genPDF(layout, printBuffer.skus)
  const blob = await doc.output('blob').text()
  sendPayloadToPrintQueue(strategyParameters.queueName, blob)
  return true
}

const printStrategies = {
  zplPreview,
  zplDirect,
  zplPrintQueue,
  pdfPreview,
  pdfDownload,
  pdfPrintQueue,
}

const printBarcodes = async function(printBuffer: any, layoutName: string, store: any, strategy: string, strategyParameters: any): Promise<boolean> {
  // Fetch default Barcode layout definition for products that have no pricetagLayouts specified
  const layout = reports.getLayout(layoutName)
  const fn = printStrategies[strategy]
  if (!fn) return false
  return await fn(layout, printBuffer, strategyParameters)
}

const sku4BarcodeLabel = function(
  sku: SkuI = {
    colorFamily: '?',
    colorCodeSupplier: '?',
    articleCode: '?',
    articleDescription: '?',
    price: 0,
    subColorCode: '?',
    size: '?',
    brand: '?',
    pricetagLayouts: '',
    articleGroup: '?',
    primary: true,
    groups: [],
    vat: 21,
    vatCategory: vatCategoryE.HIGH,
    // @ts-ignore
    sizeIndex: '',
    mainColorCode: '?',
    buyPrice: 0,
    suggestedRetailPrice: 0,
    salePrice: 0,
    collection: '?',
    active: true,
    skuActive: true,
    warehouseLocation: '?',
    campaigns: '',
  },
  qty: number
) {
  return {
    ...sku,
    color: sku.colorFamily,
    colorCodeSupplier: sku.colorCodeSupplier,
    code: sku.articleCode,
    description: sku.articleDescription,
    price: sku.price || 0,
    subColor: sku.subColorCode || '',
    size: sku.size,
    barcode: sku.id,
    qty: qty,
    brand: sku.brand,
    pricetagLayouts: sku.pricetagLayouts || 'pricetag',
    collection: sku.collection,
    salePrice: sku.salePrice || 0,
    articleGroup: sku.articleGroup,
    campaigns: sku.campaigns || '',
  }
}
const printReport = function(layoutName: any, printBuffer: any, store: any, pdfName: string): void {
  const layout = reports.getLayout(layoutName)
  if (!layout) {
    store.dispatch('raiseAlert', { header: 'layout_not_found', body: layoutName, type: 'warning', timeout: 10000 })
    return
  }

  const doc = reports.genPDF(layout, printBuffer, store)
  doc.save(pdfName + '.pdf')

  // doc.output('dataurlnewwindow')
}
export default {
  printBarcodes,
  sku4BarcodeLabel,
  printReport,
}
