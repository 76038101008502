<template>
  <v-container fill-height fluid class="ma-0 pa-0 hero">
    <nav>
      <v-app-bar app flat style="background-color: #0000">
        <v-toolbar-title>
          <v-img width="50px" contain src="../assets/barcode-logo.png"></v-img>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn style="min-width: 26px !important" color="white" data-test="changeLanguageButton" small v-bind="attrs" v-on="on">
                {{ language }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in languages" :key="index" @click="setLanguage(item.value)">
                <v-list-item-title :data-test="`languageOption-${item.value}`">{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-app-bar>
    </nav>
    <v-row justify="center" align="center" class="signup-form">
      <v-card :class="formWidth" class="pa-4" elevation="8">
        <v-card-title>{{ swT('signup') }}</v-card-title>
        <v-card-subtitle>{{ swT('create_lc_account') }}</v-card-subtitle>
        <v-card-text class="pa-2">
          <v-form ref="form" v-model="valid" lazy-validation @submit.prevent>
            <v-text-field
              v-model="companyName"
              outlined
              dense
              data-test="CompanyNameField"
              type="text"
              :rules="companyNameRules"
              class="px-1"
              :label="swT('company_name')"
              required
              @keyup.native.enter="signUpRequest"
            ></v-text-field>

            <v-text-field
              v-model="email"
              outlined
              dense
              data-test="emailField"
              type="email"
              :rules="emailRules"
              class="px-1"
              :label="swT('email')"
              required
              @keyup.native.enter="signUpRequest"
            ></v-text-field>

            <v-row class="py-4 d-flex justify-center">
              <v-btn color="primary" data-test="signupButton" class="mr-4" @click="signUpRequest">
                {{ swT('signup') }}
              </v-btn>
            </v-row>
            <v-row class="text-center message-box">
              <v-spacer></v-spacer>
              <p v-if="message" class="text-center blue--text font-weight-bold message">{{ message }}</p>
              <p v-if="singleErrorMessage" class="text-center red--text font-weight-bold message">{{ singleErrorMessage }}</p>
              <div v-if="errors !== ''">
                <div v-for="(error, idx) in errors" :key="idx">
                  <p class="text-center red--text font-weight-bold message">
                    {{ error }}
                  </p>
                </div>
              </div>
              <v-spacer></v-spacer>
            </v-row>
            <v-row class="py-4 d-flex align-center justify-center">
              <p class="text-center mb-0">
                {{ swT('already_have_account') }}
              </p>
              <v-btn color="primary" text @click="$router.push('/login')">{{ swT('login') }}</v-btn>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-row>
    <v-overlay v-if="loadingStatus" class="overlay">
      <transition name="fade" mode="out-in">
        <v-img src="../assets/loading/Loading_Barcode.gif" height="100%" width="100%"></v-img>
      </transition>
    </v-overlay>
  </v-container>
</template>

<script>
import authentication from '@/functions/authentication'
import language from '@/functions/language'
import { swT } from '@/functions/i18n'
import consts from '@/store/consts'

export default {
  data() {
    return {
      swT,
      valid: true,
      companyName: '',
      companyNameRules: [(v) => !!v || swT('required')],
      email: '',
      emailRules: [(v) => !!v || swT('required'), (v) => /.+@.+\..+/.test(v) || swT('email_must_be_valid')],
      language: this.$store.state.locale,
      languages: consts.allConfig.UISettings.language.items,
      message: '',
      errors: {},
      singleErrorMessage: '',
      loadingStatus: false,
    }
  },
  computed: {
    formWidth() {
      return this.$vuetify.breakpoint.smAndDown ? '' : 'wide'
    },
  },
  methods: {
    setLanguage(languageCode) {
      this.language = language.setLanguage(this.$store, languageCode)
      this.$store.state.beforeAuthLanguage = languageCode
    },
    async signUpRequest() {
      if (!this.$refs.form.validate()) return
      this.loadingStatus = true

      const response = await authentication.signUp(this.language, this.companyName, this.email)
      this.message = ''
      this.errors = {}
      this.singleErrorMessage = ''

      if (response.data.response == 'ok') {
        this.message = swT('check_mailbox_to_finish_process')
        this.errors = ''
      } else {
        if (typeof response.data.details !== 'undefined') {
          this.errors = response.data.details
        } else {
          this.singleErrorMessage = response.data.error
        }
      }

      this.loadingStatus = false
    },
  },
}
</script>

<style scoped>
.wide {
  min-width: 480px;
}
.hero {
  background: url('https://picsum.photos/1920/1080?random');
  background-size: cover;
  background-position: center;
  height: 100vh;
}
.signup-form {
  max-width: 100vw !important;
  margin: 0;
}
.message-box {
  max-width: 480px;
}
.overlay {
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
