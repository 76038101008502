<template>
  <v-dialog v-hotkey="keymap" :value="value" max-width="500">
    <v-card>
      <v-card-title class="headline warning white--text">
        {{ swT('alert') }}
      </v-card-title>
      <v-card-text class="my-4">
        <pre v-if="multiLineMessage">{{ message }}</pre>
        <p v-else>{{ message }}</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn data-test="yesButton" color="green" large text @click.native="$emit('dialogServiceEvent', 'yes')">
          {{ swT(yesKey) }}
        </v-btn>
        <v-btn v-if="showNoKey" data-test="noButton" color="red" large text @click.native="$emit('dialogServiceEvent', 'no')">
          {{ swT(noKey) }}
        </v-btn>
        <v-btn data-test="cancelButton" color="warning" large text @click.native="cancel">
          {{ swT(cancelKey) }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { swT } from '@/functions/i18n'

export default {
  props: {
    value: Boolean,
    message: String,
    yesKey: { type: String, default: 'yes' },
    noKey: { type: String, default: 'no' },
    cancelKey: { type: String, default: 'cancel' },
    multiLineMessage: { type: Boolean, default: false },
    showNoKey: { type: Boolean, default: true },
  },
  data() {
    return {
      swT,
    }
  },
  computed: {
    keymap() {
      return {
        esc: this.cancel,
      }
    },
  },
  methods: {
    cancel() {
      this.$emit('dialogServiceEvent')
    },
  },
}
</script>

<style scoped>
pre {
  font-family: 'Roboto' !important;
}
</style>
