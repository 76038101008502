<template>
  <div>
    <div v-if="$vuetify.breakpoint.xs" class="savecancel">
      <v-btn class="mr-2 py-5 success" data-test="saveButton" :disabled="!$store.state.activeFormValid" small @click="save">
        <v-icon>mdi-check</v-icon>
      </v-btn>
      <v-btn class="py-5 error" data-test="cancelButton" small @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div v-else>
      <v-btn class="mx-2 success" data-test="saveButton" :data-cy="`saveButton-disabled-${!$store.state.activeFormValid}`" :disabled="!$store.state.activeFormValid" @click="save">
        {{ swT('save') }}
      </v-btn>
      <v-btn class="mx-2 error" data-test="cancelButton" @click="cancel">
        {{ swT('cancel') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { swT } from '@/functions/i18n'
import { eventBus } from '../main'
export default {
  data() {
    return {
      swT,
      // todo
    }
  },
  methods: {
    save() {
      eventBus.$emit('save')
    },
    cancel() {
      eventBus.$emit('cancel')
    },
  },
}
</script>
<style scoped>
.savecancel {
  width: 120px;
  text-align: right;
}
</style>
