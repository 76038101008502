<template>
  <div>
    <v-tooltip bottom color="rgba(0,0,0,0)" dark>
      <template v-slot:activator="{ on }">
        <v-icon :class="shake ? 'shake' : ''" v-on="on" @click="infoClick">mdi-help-circle-outline</v-icon>
      </template>
      <v-card elevation="4" width="50vw">
        <div v-for="(info, id) in $store.getters.prioritizedInfo" :key="id">
          <v-card-text v-if="info.tooltipContent">
            <v-row>
              <v-col>
                {{ info.tooltipContent }}
              </v-col>
            </v-row>
          </v-card-text>
        </div>
      </v-card>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shake: false,
    }
  },
  watch: {
    '$store.state.info.data': {
      handler() {
        this.shake = true
        setTimeout(() => {
          this.shake = false
        }, 1500)
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    infoClick() {
      if (this.$store.state.info.data.length) this.$store.state.info.showInfoDrawer = !this.$store.state.info.showInfoDrawer
    },
  },
}
</script>

<style lang="scss" scoped>
.shake {
  animation: shake 1.5s ease-in-out 1;
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(10deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  40% {
    transform: rotate(20deg);
  }
  50% {
    transform: rotate(-20deg);
    color: green;
  }
  60% {
    transform: rotate(20deg);
  }
  70% {
    transform: rotate(-20deg);
  }
  80% {
    transform: rotate(15deg);
  }
  90% {
    transform: rotate(-15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>
