<template>
  <div class="d-flex flex-row" style="width: 220px">
    <v-dialog v-if="localHideDates[0]" ref="dialog0" v-model="modalFromDatePicker" :return-value.sync="dates[0]" persistent max-width="290px">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-if="localHideDates[0]"
          v-model="dates[0]"
          dense
          :label="localLabels[0] ? swT(localLabels[0]) : swT('startdate')"
          :prepend-icon="showIcons ? 'mdi-calendar' : ''"
          hide-details
          outlined
          :disabled="localDisabled[0]"
          readonly
          v-bind="attrs"
          class="daterange-input mr-1"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker v-model="dates[0]" :min="dateMin" :max="dateMax" @input="$refs.dialog0.save(dates[0])" @change="updateDates"></v-date-picker>
    </v-dialog>
    <v-dialog v-if="localHideDates[1]" ref="dialog1" v-model="modalToDatePicker" :return-value.sync="dates[1]" persistent max-width="290px">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dates[1]"
          dense
          :label="localLabels[1] ? swT(localLabels[1]) : swT('enddate')"
          :prepend-icon="showIcons ? 'mdi-calendar' : ''"
          hide-details
          outlined
          readonly
          :disabled="localDisabled[1]"
          v-bind="attrs"
          class="daterange-input ml-1"
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker v-model="dates[1]" :min="dateMin" :max="dateMax" @input="$refs.dialog1.save(dates[1])" @change="updateDates"></v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
import { swT } from '@/functions/i18n'
import { format, subDays } from 'date-fns'

export default {
  name: 'SwDateRangePicker',
  props: ['minDate', 'maxDate', 'startDate', 'endDate', 'showIcons', 'labels', 'hideDates', 'ignoreFirstEmit', 'disabled'],
  data() {
    return {
      swT,
      modalFromDatePicker: false,
      modalToDatePicker: false,
    }
  },
  computed: {
    localDisabled() {
      return this.disabled || [false, false]
    },
    localHideDates() {
      return this.hideDates || [true, true]
    },
    localLabels() {
      return this.labels || ['', '']
    },
    dates: {
      get: function() {
        return [this.dateStart, this.dateEnd]
      },
      set: function(newVal) {
        return newVal
      },
    },
    dateMin() {
      return this.minDate ? this.minDate : process.env.NODE_ENV === 'development' ? '2021-01-01' : format(subDays(new Date(), 365), 'yyyy-MM-dd')
    },
    dateMax() {
      return this.maxDate || format(new Date(), 'yyyy-MM-dd')
    },
    dateStart() {
      return this.startDate ? this.startDate : process.env.NODE_ENV === 'development' ? '2021-01-04' : format(subDays(new Date(), 30), 'yyyy-MM-dd')
    },
    dateEnd() {
      return this.endDate || format(new Date(), 'yyyy-MM-dd')
    },
  },
  watch: {
    $props: {
      handler() {
        if (this.startDate && this.endDate) this.dates = [this.startDate, this.endDate]
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (!this.ignoreFirstEmit) this.updateDates()
  },
  methods: {
    updateDates() {
      this.$emit('updateDateRange', this.dates)
    },
  },
}
</script>

<style scoped>
.daterange-input {
  pointer-events: auto;
}
</style>
