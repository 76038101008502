<template>
  <v-dialog v-hotkey="keymap" :value="value" max-width="500" transition="fab-transition">
    <v-card>
      <v-card-title class="headline info white--text">
        {{ swT(title) }}
      </v-card-title>
      <v-card-text class="my-4">
        <p>{{ swT(message) }}</p>
      </v-card-text>
      <v-autocomplete
        v-model="list"
        outlined
        hide-details
        :items="items"
        :label="swT(multiple ? 'selectMultiple' : 'selectOne')"
        :multiple="multiple"
        small-chips
        deletable-chips
        clearable
        dense
        class="pa-6"
      ></v-autocomplete>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" large text data-test="cancelButton" @click.native="cancel">{{ swT('cancel') }}</v-btn>
        <v-btn color="info" large text data-test="okButton" @click.native="ok">{{ swT('ok') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { swT } from '@/functions/i18n'

export default {
  props: ['value', 'items', 'message', 'multiple', 'title', 'context'],
  data() {
    return {
      swT,
      list: [],
    }
  },
  computed: {
    keymap() {
      return {
        esc: this.cancel,
      }
    },
  },
  watch: {
    $props: {
      handler() {
        if (this.multiple) this.list = []
        else this.list = ''
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    cancel() {
      this.$emit('dialogServiceEvent')
    },
    ok() {
      this.$emit('dialogServiceEvent', { context: this.context, value: this.list })
    },
  },
}
</script>
