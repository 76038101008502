import { deepCopy } from '@softwear/latestcollectioncore'

const zplStart = '^XA'
const zplStop = '^XZ'
const fieldOrigin = '^FO'
const fieldSeparator = '^FS'
const fieldData = '^FD'
const zplAlign = ['L', 'L', 'C', 'R']
// We only support 203 dpi barcode printers:
const dpmm = 8 // 203 dpi

let activeColumn = 0

const scaleForZPL = function(unit: number, dpmm: number): number {
  return parseInt(String((dpmm * unit) / 10))
}
const mustUseFieldBlockForLabel = function(label: any, layout: any) {
  return label.name == '    ||| || |||| ||   ' || label.textAlign == 2 || layout.rotation != 0 || label.textAlign == 3 || layout?.truncate == false
}
const textBlockCommand = function(label: any, layout: any) {
  const rotation = layout.rotation
  let textBlock = '^TB'
  switch (rotation) {
    case 0:
      textBlock += 'N'
      break
    case 90:
      textBlock += 'R'
      break
    case 180:
      textBlock += 'I'
      break
    case 270:
      textBlock += 'B'
      break
  }
  textBlock += `,${label.width},${label.height}`
  return textBlock
}
const calculateFieldOrigin = function(label: any, layout: any): string {
  const rotation = layout.rotation
  const paperSize = layout.paperSize
  let x, y
  switch (rotation) {
    case 0:
      x = scaleForZPL(label.x, dpmm)
      y = scaleForZPL(label.y, dpmm)
      break
    case 90:
      x = scaleForZPL(paperSize.height - label.y - label.height, dpmm)
      y = scaleForZPL(label.x, dpmm)
      break
    case 180:
      x = scaleForZPL(paperSize.width - label.x - label.width, dpmm)
      y = scaleForZPL(paperSize.height - label.y - label.height, dpmm)
      break
    case 270:
      x = scaleForZPL(label.y, dpmm)
      y = scaleForZPL(paperSize.width - label.x - label.width, dpmm)
      break
  }
  return `${fieldOrigin}${x},${y}`
}
const oneLabel = function(layout: any, sku: any): string {
  const rotation = layout.rotation
  let zpl = '^CI28'
  layout.objects
    .filter((obj) => obj.active)
    .forEach((label) => {
      let text = ''
      switch (label.name) {
        case '€Price':
          text = '€ ' + (sku.salePrice ? sku.salePrice : sku.price).toFixed(2)
          break
        case '€OriginalPrice':
          text = sku.salePrice ? '€ ' + sku.price.toFixed(2) : ''
          break
        case 'Size':
          text = sku.size || ''
          break
        case 'Color':
          text = sku.color || ''
          break
        case 'Color code supplier':
          text = sku.colorCodeSupplier || ''
          break
        case 'Code':
          text = sku.code
          break
        case 'Description':
          text = sku.description
          break
        case 'Brand':
          text = sku.brand
          break
        case '    ||| || |||| ||   ':
          text = sku.barcode
          break
        case 'Barcode':
          text = sku.barcode
          break
        case 'QR-Code':
          text = sku.barcode
          break
        case 'Logo':
          text = '^XGR:LOGO.GRF,1,1'
          break
        case 'Van':
          text = sku.salePrice ? 'Van' : ''
          break
        case 'Voor':
          text = sku.salePrice ? 'Voor' : ''
          break
        default:
          if (label.type == 'field' && label.source) {
            text = sku[label.source]
            if (typeof text == 'number') text = '' + text
            if (text === undefined || typeof text != 'string') {
              console.log('ZPL renderer: fieldnotfound', label.source)
              text = ''
            }
            break
          }
          text = label.text || ''
      }
      if (label.case == 1) text = text.toUpperCase()
      if (label.case == 2) text = text.toLowerCase()

      const fontHeight = scaleForZPL(label.fontSize, 12)
      const fontWidth = scaleForZPL(label.fontSize, 10)
      const coordinates = calculateFieldOrigin(label, layout)
      if (mustUseFieldBlockForLabel(label, layout)) {
        zpl += coordinates
      } else {
        zpl += coordinates + ',0'
      }
      if (label.type == 'rectangle') {
        zpl += `^GB${scaleForZPL(rotation == 90 || rotation == 270 ? label.height : label.width, dpmm)},${scaleForZPL(
          rotation == 90 || rotation == 270 ? label.width : label.height,
          dpmm
        )}`
      } else if (label.name == 'QR-Code') {
        zpl += `^BQ,2,3`
        text = 'MM,A' + text
      } else {
        if (mustUseFieldBlockForLabel(label, layout)) {
          zpl += `^FB${scaleForZPL(label.width, dpmm)},1,0,${zplAlign[label.textAlign || 1]},0`
        }
        if (label.name == '    ||| || |||| ||   ') {
          if (text.length == 13) {
            zpl += `^BY3,,`
            zpl += `^BE,${fontHeight},Y,N`
          } else {
            zpl += `^BY2,,`
            zpl += `^BC,${fontHeight},Y,N`
          }
        } else zpl += `^A0,${fontHeight},${fontWidth}`
      }
      if (label.name != '    ||| || |||| ||   ' && label.name != 'QR-Code' && label.name != 'Logo') {
        const maxTextlength = (2 * label.width) / fontWidth
        text = text + ''
        text = text.substring(0, maxTextlength)
      }
      if (mustUseFieldBlockForLabel(label, layout)) {
        zpl += `${fieldData}${text}${fieldSeparator}`
      } else {
        zpl += `${textBlockCommand(label, layout)}${fieldData}${text}${fieldSeparator}`
      }
    })
  return zpl
}
const genZPL = function(layout: any, printBuffer: any): any {
  const layoutCopy = deepCopy(layout)
  activeColumn = 0

  if (!layoutCopy || !printBuffer) return false
  if (!layoutCopy.objects) return false
  if (!printBuffer.skus) return false
  const paperSize = layoutCopy.paperSize
  if (!paperSize) return false
  if (typeof paperSize.width != 'number' || typeof paperSize.height != 'number') return false

  // Layout model stores paperSize in mm, x,y coordinates of objects are in tenths of mm
  // Convert everything to tenths of mm
  paperSize.width = paperSize.width * 10
  paperSize.height = paperSize.height * 10
  paperSize.paperLeftOffset = paperSize.paperLeftOffset * 10
  paperSize.horizontalGap = paperSize.horizontalGap * 10

  if (!layoutCopy.rotation) layoutCopy.rotation = 0
  let zpl = zplStart
  switch (layoutCopy.rotation) {
    case 0:
      zpl = zpl + '^FWN'
      break
    case 90:
      zpl = zpl + '^FWR'
      break
    case 180:
      zpl = zpl + '^FWI'
      break
    case 270:
      zpl = zpl + '^FWB,2'
  }
  const logo = layoutCopy.objects.find((label) => label.name == 'Logo')
  if (logo?.active) {
    // ~DGR command defines an image to be used later.
    // An image can be generated from www.softwear.nl/zpl
    zpl = zpl + `~DGR:LOGO.GRF,${logo.text}`
  }

  if (layoutCopy.mediaDarkness) {
    zpl = zpl + `^MD${layoutCopy.mediaDarkness}`
  }
  printBuffer.skus.forEach((sku) => {
    for (let copy = 0; copy < sku.qty; copy++) {
      zpl = zpl + '^LH' + scaleForZPL(activeColumn * (layout.horizontalGap + paperSize.width) + layout.paperLeftOffset, dpmm)
      zpl = zpl + oneLabel(layoutCopy, sku)
      activeColumn = activeColumn + 1
      if (activeColumn == layoutCopy.nrAcross) zpl += zplStop + zplStart
      activeColumn = activeColumn % layoutCopy.nrAcross
    }
  })
  return zpl + zplStop
}
export default {
  genZPL,
}
