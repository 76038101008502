import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{directives:[{name:"hotkey",rawName:"v-hotkey",value:(_vm.keymap),expression:"keymap"}],attrs:{"value":_vm.value,"max-width":"500","transition":"fab-transition"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline info white--text"},[_vm._v(" "+_vm._s(_vm.swT(_vm.title))+" ")]),_c(VCardText,{staticClass:"my-4"},[_c('p',[_vm._v(_vm._s(_vm.swT(_vm.message)))])]),_c(VAutocomplete,{staticClass:"pa-6",attrs:{"outlined":"","hide-details":"","items":_vm.items,"label":_vm.swT(_vm.multiple ? 'selectMultiple' : 'selectOne'),"multiple":_vm.multiple,"small-chips":"","deletable-chips":"","clearable":"","dense":""},model:{value:(_vm.list),callback:function ($$v) {_vm.list=$$v},expression:"list"}}),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"red","large":"","text":"","data-test":"cancelButton"},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.swT('cancel')))]),_c(VBtn,{attrs:{"color":"info","large":"","text":"","data-test":"okButton"},nativeOn:{"click":function($event){return _vm.ok.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.swT('ok')))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }