/* Generating barcodes to a PDF will go here */

import { jsPDF } from 'jspdf'
import JsBarcode from 'jsbarcode'
import Canvas from 'canvas'
import { LayoutBarcode } from '../types/barcodePDF'

function genPDF(layout: any, printBuffer: any): jsPDF {
  const layoutBarcode: LayoutBarcode = {
    name: layout.name,
    horizontalGap: layout.horizontalGap,
    verticalGap: layout.verticalGap,
    nrAcross: layout.nrAcross,
    paperSize: layout.paperSize,
    objects: layout.objects,
  }

  const paperSize = layoutBarcode.paperSize
  const doc = new jsPDF({
    orientation: paperSize.width > paperSize.height ? 'landscape' : 'portrait',
    format: [paperSize.width * layoutBarcode.nrAcross + layoutBarcode.horizontalGap * (layoutBarcode.nrAcross - 1), paperSize.height],
  })
  doc.autoPrint({ variant: 'non-conform' })
  let activeColumn = 0
  for (const sku of printBuffer) {
    const canvas = Canvas.createCanvas(10, 1)
    JsBarcode(canvas, sku.barcode, {
      width: 10,
      height: 1,
      displayValue: false,
      margin: 0,
    })
    for (let copy = 0; copy < sku.qty; copy++) {
      layoutBarcode.objects.forEach((label) => {
        let text = ''
        if (label.active) {
          doc.setFontSize(label.fontSize / 4)
          switch (label.name) {
            case '€Price':
              text = String.fromCharCode(128) + ' ' + (sku.salePrice ? sku.salePrice : sku.price).toFixed(2)
              break
            case '€OriginalPrice':
              text = sku.salePrice ? String.fromCharCode(128) + ' ' + sku.price.toFixed(2) : ''
              break
            case 'Size':
              text = sku.size || ''
              break
            case 'Color':
              text = sku.color || ''
              break
            case 'Color code supplier':
              text = sku.colorCodeSupplier || ''
              break
            case 'Code':
              text = sku.code
              break
            case 'Description':
              text = sku.description
              break
            case 'Brand':
              text = sku.brand
              break
            case '    ||| || |||| ||   ':
              text = sku.barcode
              break
            case 'Barcode':
              text = sku.barcode
              break
            case 'QR-Code':
              text = sku.barcode
              break
            case 'Van':
              text = sku.salePrice ? 'Van' : ''
              break
            case 'Voor':
              text = sku.salePrice ? 'Voor' : ''
              break
            default:
              if (label.type == 'field' && label.source) {
                text = sku[label.source]
                if (typeof text == 'number') text = '' + text
                if (text === undefined || typeof text != 'string') {
                  console.log('ZPL renderer: fieldnotfound', label.source)
                  text = ''
                }
                break
              }
              text = label.text || ''
          }
          if (label.case == 1) text = text.toUpperCase()
          if (label.case == 2) text = text.toLowerCase()
          let x = label.x / 10 + activeColumn * (layoutBarcode.horizontalGap + paperSize.width)
          const y = label.y / 10 + label.fontSize / 10
          if (label.name == '    ||| || |||| ||   ') {
            // Barcode image, which orgin is on the left is added before the x-offset is calculated for the HR text
            // because that might be centered
            // const barcodeX =
            //   (paperSize.width + layoutBarcode.horizontalGap) * activeColumn +
            //   paperSize.width * 0.1
            //doc.addImage(canvas, "JPEG", barcodeX, y - 7, paperSize.width * .8, 4)
            doc.addImage(canvas.toDataURL(), 'JPEG', x, y - 7, label.width / 10, 4)
          }
          if (label.textAlign == 2) {
            // Center: origin is in the middle of the label
            x = x + label.width / 10 / 2
          }
          if (label.textAlign == 3) {
            // Right: origin is at far right
            x = x + label.width / 10
          }
          doc.setFont(label.fontFamily)

          if (text)
            doc.text(text, x, y, {
              align: label.textAlign == 1 ? 'left' : label.textAlign == 2 ? 'center' : 'right',
            })
        }
      })
      activeColumn = activeColumn + 1
      if (activeColumn == layoutBarcode.nrAcross) doc.addPage()
      activeColumn = activeColumn % layoutBarcode.nrAcross
    }
  }

  return doc
}
export default {
  genPDF,
}
