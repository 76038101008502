<template>
  <span v-if="largeQty" :class="align">
    {{ typeof amount == 'number' ? '€&nbsp;' + largeQtyVisualize(amount) : '' }}
  </span>
  <span v-else :class="align">
    {{ typeof amount == 'number' ? '€&nbsp;' + amount.toFixed(2) : '' }}
  </span>
</template>

<script>
import { largeQtyVisualize } from '../functions/largeQtyVisualization'
export default {
  props: ['amount', 'alignleft', 'largeQty'],
  computed: {
    align() {
      if (this.alignleft) return 'text-left'
      else return 'text-right'
    },
  },
  methods: {
    largeQtyVisualize,
  },
}
</script>
