<template>
  <span>
    <span v-if="authorizedFor">
      <slot></slot>
    </span>
    <span v-show="!authorizedFor">
      <slot name="otherwise"></slot>
    </span>
  </span>
</template>

<script>
import user from '../functions/users'

export default {
  props: ['role'],
  computed: {
    authorizedFor() {
      return user.hasRole(this.$store.getters.roles, this.role)
    },
  },
}
</script>
