import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{directives:[{name:"hotkey",rawName:"v-hotkey",value:(_vm.keymap),expression:"keymap"}],attrs:{"value":_vm.value,"width":"500","max-width":"800"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline info white--text"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.message !== 'no message')?_c(VCardText,[_c('p',{domProps:{"innerHTML":_vm._s(_vm.message ? _vm.message : _vm.swT('prompt_dialog_default_message').replace('{title}', _vm.swT(_vm.title).toLowerCase()))}})]):_vm._e(),_c(VCardText,[_c(VTextField,{attrs:{"rules":[function () { return !_vm.validatePercentage || !_vm.promptValue || _vm.allowedPercentage(_vm.promptValue) || _vm.swT('percentage_value_too_high'); }],"type":_vm.type ? _vm.type : 'text',"suffix":_vm.suffix,"data-test":_vm.dataTest ? _vm.dataTest + '-promptField' : 'promptField',"label":_vm.placeholder,"outlined":"","autofocus":"","min":_vm.validatePercentage ? 1 : null,"max":_vm.validatePercentage ? 100 : null},on:{"keypress":_vm.onlyAllowPositiveFloatNumbers},model:{value:(_vm.promptValue),callback:function ($$v) {_vm.promptValue=$$v},expression:"promptValue"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"data-test":_vm.dataTest ? _vm.dataTest + '-okButton' : 'okButton',"data-cy":("okButton-disabled-" + (!_vm.allowEmpty && _vm.promptValue == '')),"color":"green","large":"","text":"","disabled":(!_vm.allowEmpty && _vm.promptValue == '') || (_vm.validatePercentage && !_vm.allowedPercentage(_vm.promptValue))},nativeOn:{"click":function($event){return _vm.handleOk.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.swT('ok'))+" ")]),_c(VBtn,{attrs:{"data-test":_vm.dataTest ? _vm.dataTest + '-cancelButton' : 'cancelButton',"color":"red","large":"","text":""},nativeOn:{"click":function($event){return _vm.handleCancel.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.swT('cancel'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }