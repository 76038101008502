import axios from 'axios'
import consts from '../store/consts'
import cookies from 'js-cookie'

const authenticate = async function(username: string, password: string): Promise<object> {
  const urlFriendlyPassword = encodeURIComponent(password)
  const urlFriendlyUsername = encodeURIComponent(username)

  try {
    const loginRequest = await axios.get(`${consts.webServicesPath}/v1/login?username=${urlFriendlyUsername}&password=${urlFriendlyPassword}`)
    return loginRequest.data
  } catch (e) {
    return null
  }
}
const validateOTP = async function(mfaToken: string, otp: string): Promise<object> {
  try {
    const loginRequest = await axios.get(`${consts.webServicesPath}/v1/validateOTP?mfaToken=${mfaToken}&otp=${otp}`)
    return loginRequest.data
  } catch (e) {
    return null
  }
}

const signUp = async function(language: string, companyName: string, email: string): Promise<any> {
  try {
    const signUpRequest = await axios.post(
      `${consts.webServicesPath}/v1/signup`,
      {
        companyName: companyName,
        email: email,
      },
      {
        headers: {
          'Accept-Language': language,
        },
        validateStatus: () => true,
      }
    )

    return signUpRequest
  } catch (error) {
    return null
  }
}

const requestResetPassword = async function(language: string, email: string): Promise<any> {
  try {
    const resetPasswordRequest = await axios.post(
      `${consts.webServicesPath}/v1/resetpassword`,
      {
        email: email,
      },
      {
        headers: {
          'Accept-Language': language,
        },
        validateStatus: () => true,
      }
    )
    return resetPasswordRequest
  } catch (error) {
    return null
  }
}

const resetPassword = async function(language: string, key: string, username: string, password: string, confirmPassword: string): Promise<any> {
  let readableUsername = username

  if (username.includes(' ')) {
    readableUsername = username.replace(' ', '+')
  }

  try {
    const resetPasswordRequest = await axios.post(
      `${consts.webServicesPath}/v1/setnewpassword`,
      {
        key: key,
        username: readableUsername,
        password: password,
        confirmPassword: confirmPassword,
      },
      {
        headers: {
          'Accept-Language': language,
        },
        validateStatus: () => true,
      }
    )
    return resetPasswordRequest
  } catch (error) {
    return null
  }
}

const set2fa = async function(onOff: boolean, user: string, password: string): Promise<any> {
  try {
    const set2faRequest = await axios.post(
      `${consts.webServicesPath}/v1/set2fa`,
      {
        TwoFactorAuthEnabled: onOff,
        username: user,
        password: password,
        sid: cookies.get('sid'),
      },
      {
        validateStatus: () => true,
      }
    )
    return set2faRequest.data
  } catch (error) {
    return null
  }
}

export default {
  authenticate,
  validateOTP,
  set2fa,
  signUp,
  requestResetPassword,
  resetPassword,
}
