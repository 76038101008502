import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{directives:[{name:"hotkey",rawName:"v-hotkey",value:(_vm.keymap),expression:"keymap"}],attrs:{"value":_vm.value,"max-width":"500"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline info white--text"},[_vm._v(" "+_vm._s(_vm.swT('xls-import'))+" ")]),_c(VCardText,[_c(VCard,{staticClass:"ma-2",attrs:{"color":"green lighten-3"}},[_c(VCardText,[_vm._v(" "+_vm._s(_vm.swT('xls_import_only_new'))+" ")]),_c(VCardActions,[_c(VBtn,{attrs:{"data-test":"onyNewButton","large":""},nativeOn:{"click":function($event){return _vm.$emit('dialogServiceEvent', 'new_only')}}},[_vm._v(" "+_vm._s(_vm.swT('new_only'))+" ")])],1)],1),_c(VCard,{staticClass:"ma-2",attrs:{"color":"warning lighten-3"}},[_c(VCardText,[_vm._v(" "+_vm._s(_vm.swT('xls_import_only_existing'))+" ")]),_c(VCardActions,[_c(VBtn,{attrs:{"data-test":"onyExistingButton","large":""},nativeOn:{"click":function($event){return _vm.$emit('dialogServiceEvent', 'update_only')}}},[_vm._v(" "+_vm._s(_vm.swT('update_only'))+" ")])],1)],1),_c(VCard,{staticClass:"ma-2",attrs:{"color":"error lighten-3"}},[_c(VCardText,[_vm._v(" "+_vm._s(_vm.swT('xls_import_all'))+" ")]),_c(VCardActions,[_c(VBtn,{attrs:{"data-test":"allButton","large":""},nativeOn:{"click":function($event){return _vm.$emit('dialogServiceEvent', 'all')}}},[_vm._v(" "+_vm._s(_vm.swT('all'))+" ")])],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"data-test":"cancelButton","color":"info","large":"","text":""},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.swT('cancel'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }