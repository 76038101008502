<template>
  <v-dialog v-hotkey="keymap" :value="value" width="500" max-width="800">
    <v-card>
      <v-card-title class="headline info white--text">
        {{ title }}
      </v-card-title>
      <v-card-text v-if="message !== 'no message'">
        <p v-html="message ? message : swT('prompt_dialog_default_message').replace('{title}', swT(title).toLowerCase())"></p>
      </v-card-text>
      <v-card-text>
        <v-text-field
          v-model="promptValue"
          :rules="[() => !validatePercentage || !promptValue || allowedPercentage(promptValue) || swT('percentage_value_too_high')]"
          :type="type ? type : 'text'"
          :suffix="suffix"
          :data-test="dataTest ? dataTest + '-promptField' : 'promptField'"
          :label="placeholder"
          outlined
          autofocus
          :min="validatePercentage ? 1 : null"
          :max="validatePercentage ? 100 : null"
          @keypress="onlyAllowPositiveFloatNumbers"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :data-test="dataTest ? dataTest + '-okButton' : 'okButton'"
          :data-cy="`okButton-disabled-${!allowEmpty && promptValue == ''}`"
          color="green"
          large
          text
          :disabled="(!allowEmpty && promptValue == '') || (validatePercentage && !allowedPercentage(promptValue))"
          @click.native="handleOk"
        >
          {{ swT('ok') }}
        </v-btn>
        <v-btn :data-test="dataTest ? dataTest + '-cancelButton' : 'cancelButton'" color="red" large text @click.native="handleCancel">
          {{ swT('cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { swT } from '@/functions/i18n'

export default {
  props: ['value', 'title', 'defaultValue', 'dataTest', 'message', 'type', 'suffix', 'validatePercentage', 'placeholder', 'allowEmpty'],
  data() {
    return {
      swT,
      promptValue: '',
    }
  },
  computed: {
    keymap() {
      return {
        enter: this.handleOk,
        esc: this.handleCancel,
      }
    },
  },
  created() {
    this.promptValue = this.defaultValue ? this.defaultValue : ''
    this.$watch('value', () => {
      if (!this.value) this.promptValue = ''
    })
  },
  methods: {
    handleOk() {
      const value = this.promptValue
      if (this.allowEmpty || (this.validatePercentage && this.allowedPercentage(value)) || !this.validatePercentage) this.$emit('dialogServiceEvent', value)
    },
    handleCancel() {
      this.$emit('dialogServiceEvent')
    },
    allowedPercentage(percentage) {
      return percentage > 0 && percentage <= 100
    },
    onlyAllowPositiveFloatNumbers(event) {
      // Only allow . and number keys (0-9) by percentage validation
      if (!this.validatePercentage) return
      const charCode = event.key.charCodeAt(0)
      if (charCode == 46 || charCode < 48 || charCode > 57) event.preventDefault()
    },
  },
}
</script>

<style scoped>
.v-card__text {
  padding: 20px 20px 0 20px !important;
}

p {
  margin-bottom: 0;
}
</style>
